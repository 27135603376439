import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Bounce, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAddToCustomerBagMutation,
  useCheckAddCouponMutation,
  useGenerateOTPforCustomerSignupMutation,
} from "../redux/slices/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrderDetails,
  removeOrderDetails,
} from "../redux/slices/myBagSlice";
import BTN from "../b2b/Master/BTN";
import PDFPreview from "../b2b/Master/PDFPreview";

export const Signup = ({
  blueCutLensIdDataLabel,
  acceptPrivacy,
  acceptTerms,
  setPrivacyPdfToggle,
  setTermsPdfToggle,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const myBagData = useSelector((state) => state?.myBagData?.items);
  const orderDetails = useSelector((state) => state?.myBagData?.orderDetails);
  const [addToCustomerBag] = useAddToCustomerBagMutation();
  const dispatch = useDispatch();

  const [registerLoader, setRegisterLoader] = useState(false);

  // =================== CHECK COUPON IS VALID OR NOT WHEN CART CHANGE ====================
  const subTotal = () => {
    return myBagData?.reduce((total, item) => {
      // Calculate the product price based on quantity
      const productPrice = item?.prices?.price * item?.quantity;

      // Calculate the total lens price for this product (sum of all lenses)
      const totalLensPrice = item?.lensDetails?.reduce((lensTotal, lens) => {
        // Add prices of all addons for this lens
        const addonsPrice = lens.addons.reduce((addonsSum, addon) => {
          return addonsSum + addon.price;
        }, 0);
        return addonsPrice + lensTotal + lens?.price || 0;
      }, 0);

      // Add the total lens price to the product price
      const itemTotal = productPrice + (totalLensPrice || 0);

      return total + itemTotal;
    }, 0);
  };

  const checkCouponCodeIsValid = (coupon) => {
    const endTime = new Date(coupon?.endTime);
    const currentTime = new Date();

    if (currentTime > endTime) {
      return false;
    }
    if (subTotal() < coupon?.minimumAmount) {
      return false;
    }
    if (coupon?.couponQuantity === 0) {
      return false;
    }
    return true;
  };

  const checkEndDateOfCoupon = (coupon) => {
    const endTime = new Date(coupon?.endTime);
    const currentTime = new Date();
    if (currentTime > endTime || coupon?.couponQuantity === 0) {
      return false;
    } else {
      return true;
    }
  };

  const [checkAddCoupon] = useCheckAddCouponMutation();

  const signupformik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNo: "",
      email: "",
      otp: "",
    },
    onSubmit: async function (values) {
      setRegisterLoader(true);
      try {
        const response = await axios(
          process.env.REACT_APP_BASE_URL + `/customer/register`,
          {
            method: "POST",
            data: {
              firstname: values.firstName,
              lastname: values.lastName,
              email: values.email,
              otp: values.otp,
              phone: values.phoneNo,
            },
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        localStorage.setItem("user", response.data.token);
        setRegisterLoader(false);
        // ====================== ADD TO BAG HANDLE ================================

        if (location?.state?.forZeroPowerLensId) {
          addToCustomerBag({
            body: {
              products: [
                {
                  product_id: location?.state?.forZeroPowerLensId,
                  quantity: 1,
                  variant: [],
                  zeroPower: true,
                  lens: [
                    {
                      lens_id: blueCutLensIdDataLabel?.labels?.zeroPowerLensId,
                      addons: [],
                    },
                  ],
                },
              ],
            },
            user: localStorage.getItem("user"),
          }).then((res) => {
            if (res?.data) {
              toast.success(`Lens added to bag successfully.`);
            } else {
              toast.error(`${res?.error?.data?.message}`);
            }
          });
        }

        // ====================== ADD TO BAG HANDLE ================================

        if (myBagData && myBagData?.length > 0) {
          myBagData.forEach((item) => {
            addToCustomerBag({
              body: {
                products: [
                  {
                    product_id: item?._id,
                    quantity: item?.quantity,
                    readingGlassesPower: item?.readingGlassesPower
                      ? item?.readingGlassesPower
                      : [],
                    readingGlasses: item?.isReadingGlasses
                      ? item?.isReadingGlasses
                      : false,
                    variant: [],
                    lens: [],
                  },
                ],
              },
              user: response.data.token,
            }).then((res) => {
              if (res?.data) {
                // toast.success(`${item?.name} added to bag successfully.`);
              } else {
                // toast.error(`${res?.error?.data?.message}`);
              }
            });
          });
          setTimeout(() => {
            if (orderDetails?.couponCode) {
              if (
                checkCouponCodeIsValid(orderDetails?.couponCode) &&
                checkEndDateOfCoupon(orderDetails?.couponCode)
              ) {
                // dispatch(
                //   addOrderDetails({
                //     ...orderDetails,
                //     couponCode: orderDetails?.couponCode,
                //   })
                // );

                checkAddCoupon({
                  body: {
                    couponCode: orderDetails?.couponCode?.couponCode,
                  },
                  user: localStorage.getItem("user"),
                }).then((res) => {
                  if (res?.data) {
                    if (checkCouponCodeIsValid(res?.data?.data)) {
                      dispatch(
                        addOrderDetails({
                          ...orderDetails,
                          couponCode: res?.data?.data,
                        })
                      );
                    }
                  } else {
                    toast.error(`${res?.error?.data?.message}`);
                    dispatch(
                      removeOrderDetails({
                        items: myBagData,
                        orderDetails: {
                          isGift: orderDetails?.isGift,
                          giftMessage: orderDetails?.giftMessage,
                          couponCode: null,
                        },
                      })
                    );
                  }
                });
              } else {
                dispatch(
                  removeOrderDetails({
                    items: myBagData,
                    orderDetails: {
                      isGift: orderDetails?.isGift,
                      giftMessage: orderDetails?.giftMessage,
                      couponCode: null,
                    },
                  })
                );
              }
            }
          }, 0);
        }

        navigate(
          location?.state?.isNewUser === true
            ? "/shopping-bag"
            : location?.state?.id
            ? "/lens"
            : "/profile-details#your-account",
          { state: { id: location?.state?.id ? location?.state?.id : null } }
        );
        toast.success(response.data.message);
      } catch (err) {
        console.log(err);
        toast.error(err.response.data.message);
        setRegisterLoader(false);
      }
    },
    validate: (values) => {
      let errors = {};
      if (!values.firstName) {
        errors.firstName = "enter first name";
      }
      if (!values.lastName) {
        errors.lastName = "enter last name";
      }
      if (!values.phoneNo) {
        errors.phoneNo = "Phone number is Required";
      } else if (!/^[0-9]+$/i.test(values.phoneNo)) {
        errors.phoneNo = "Invalid Phone Number";
      } else if (values.phoneNo.length !== 10) {
        errors.phoneNo = "Phone Number must be 10 digits";
      }
      // if (!values.email) {
      //   errors.email = "Email is Required";
      // } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(values.email)) {
      //   errors.email = "Invalid Email";
      // }
      if (!values.otp) {
        errors.otp = "Enter OTP";
      } else if (!/^[0-9]+$/i.test(values.otp)) {
        errors.otp = "Invalid OTP";
      }
      // else if (values.otp.length !== 4) {
      //   errors.otp = "OTP must be 4 digits";
      // }
      return errors;
    },
  });

  const [
    generateOTPforCustomerSignup,
    {
      data: otpData,
      isLoading: isLoadingOTP,
      isSuccess: isSuccessOTP,
      isError: isErrorOTP,
      error: errorOTP,
    },
  ] = useGenerateOTPforCustomerSignupMutation();
  const generateOtp = (phone) => {
    if (!phone) {
      toast.error("Phone number is Required!");
    }
    if (signupformik.errors.phoneNo) {
      return;
    }
    const body = {
      phone: phone,
      event: "signup",
    };
    generateOTPforCustomerSignup({ body }).then((res) => {
      // toast success
      if (res.data) {
        toast.success(res.data.message);
      }
      console.log(res.data);
    });
  };

  return (
    <div className="signup-form lg:pl-[20%] emd:pl-0 small:pl-0">
      <h1 className="signup-title font-sabon lg:flex emd:hidden small:hidden">
        Sign Up
      </h1>
      <p className="signup-text">To create an account, fill in the details</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={signupformik.values.firstName}
            onChange={signupformik.handleChange}
            onBlur={signupformik.handleBlur}
          />
          {signupformik.errors.firstName && signupformik.touched.firstName ? (
            <div className="error-msg">{signupformik.errors.firstName}</div>
          ) : null}
        </div>
        <br />
        <div>
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={signupformik.values.lastName}
            onChange={signupformik.handleChange}
            onBlur={signupformik.handleBlur}
          />
          {signupformik.errors.lastName && signupformik.touched.lastName ? (
            <div className="error-msg">{signupformik.errors.lastName}</div>
          ) : null}
        </div>
        <br />
        <div className="phone-input-container-signup">
          <input
            type="text"
            placeholder="Ph No."
            name="phoneNo"
            value={signupformik.values.phoneNo}
            onChange={signupformik.handleChange}
            onBlur={signupformik.handleBlur}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                generateOtp(signupformik.values.phoneNo);
              } else if (e.key === "Tab") {
                generateOtp(signupformik.values.phoneNo);
              }
            }}
          />
          <div
            className="send-otp-btn-signup"
            onClick={() => {
              generateOtp(signupformik.values.phoneNo);
            }}
          >
            SEND
          </div>
        </div>
        {signupformik.errors.phoneNo && signupformik.touched.phoneNo ? (
          <div className="error-msg">{signupformik.errors.phoneNo}</div>
        ) : null}
        <br />
        <div>
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={signupformik.values.email}
            onChange={signupformik.handleChange}
            onBlur={signupformik.handleBlur}
          />
          {signupformik.errors.email && signupformik.touched.email ? (
            <div className="error-msg">{signupformik.errors.email}</div>
          ) : null}
        </div>
        <br />
        <div>
          <input
            type="text"
            placeholder="OTP"
            name="otp"
            value={signupformik.values.otp}
            onChange={signupformik.handleChange}
            onBlur={signupformik.handleBlur}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                signupformik.handleSubmit();
              }
            }}
          />
          {signupformik.errors.otp && signupformik.touched.otp ? (
            <div className="error-msg">{signupformik.errors.otp}</div>
          ) : null}
        </div>
        <br />
        <div className="w-full  mt-5 mb-10">
          <div className="w-1/2 flex items-center">
            <input
              style={{ width: "fit-content" }}
              type="checkbox"
              id="terms_condition"
              className="accent-secondary w-4 h-4 cursor-pointer"
              checked={acceptTerms}
              value={acceptTerms}
              onChange={() => setTermsPdfToggle(true)}
            />
            <label
              htmlFor="terms_condition"
              className="ms-2 text-[12px] select-none cursor-pointer whitespace-pre text-nowarp"
            >
              Accept Terms And Conditions
            </label>
          </div>

          <div className="w-1/2 flex items-center mt-3">
            <input
              style={{ width: "fit-content" }}
              type="checkbox"
              id="privacy_policy"
              className="accent-secondary w-4 h-4 cursor-pointer"
              checked={acceptPrivacy}
              value={acceptPrivacy}
              onChange={() => setPrivacyPdfToggle(true)}
            />
            <label
              htmlFor="privacy_policy"
              className="ms-2 text-[12px] select-none cursor-pointer whitespace-pre text-nowarp"
            >
              Accept Privacy And Policy
            </label>
          </div>
        </div>
        <BTN
          loading={registerLoader}
          disabled={!acceptPrivacy || !acceptTerms}
          type={"submit"}
          onP={signupformik.handleSubmit}
          bordered
          title={"CREATE ACCOUNT"}
          className={"signup-btn mb-[40px] text-[14px] signup-btn-container"}
        />
      </form>
    </div>
  );
};
