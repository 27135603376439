import "./Header.scss";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Breakpoint } from "react-socks";
import { myBagToggle } from "../../redux/slices/myBagToggleSlice";
import useGetUser from "../../hooks/useGetUser";
import INPUT from "../Master/INPUT";
import {
  BAG_IC,
  CLOSE_BLACK_IC,
  CLOSE_IC,
  HTO_LOGO,
  LIKE__IC,
  MENU_IC,
  PERSON_IC,
  SEARCH_IC,
} from "../../assets";
import BTN from "../Master/BTN";

function Header({
  isRetailerPage,
  sidebarToggle,
  setSidebarToggle,
  headerLabelsData,
  loginModelToggle,
  setLoginModelToggle,
  headerCategoriesData,
}) {
  const [isVisible, setIsVisible] = useState(true);
  const [searchToggle, setSearchToggle] = useState(false);
  const location = window.location;
  const dispatch = useDispatch();
  const user = useGetUser();
  const params = useParams();
  const loginModel = localStorage.getItem("loginModel");
  const [searchProductsVal, setSearchProductsVal] = useState(
    location?.pathname?.split("/")[1] === "search"
      ? location?.pathname?.split("/")[2]
      : ""
  );

  const wishlistData = useSelector((state) => state.wishlist.data);

  useEffect(() => {
    if (!location?.pathname.includes("search")) {
      setSearchProductsVal("");
    }
  }, [location.pathname]);
  const myBagData = useSelector((state) => state?.myBagData?.items);
  const homeTryOnData = useSelector((state) => state.homeTryOnData.items);

  useEffect(() => {
    if (location.pathname === "/login") {
      setIsVisible(false);
      return;
    }
  }, [location]);
  useEffect(() => {
    function handleScroll() {
      if (location.pathname === "/login") {
        setIsVisible(false);
        return;
      }
      if (window.scrollY > 300) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }

    window.addEventListener("scroll", handleScroll);
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  const AccountIcon = () => {
    return (
      <div className={`relative flex items-center justify-center`}>
        <Link
          to={`${user?.customer ? "/profile-details#your-account" : "/login"}`}
          className=" relative"
        >
          <img
            src={PERSON_IC}
            alt=""
            className="lg:min-w-8 lg:max-w-8 emd:min-w-8 emd:max-w-8 small:min-w-7 small:max-w-7 m-auto"
          />
        </Link>
      </div>
    );
  };
  const FavouriteIcon = () => {
    return (
      <Link to="/favourite" className="relative">
        <img
          src={LIKE__IC}
          alt=""
          className="lg:min-w-8 lg:max-w-8 emd:min-w-8 emd:max-w-8 small:min-w-7 small:max-w-7"
        />
        {wishlistData?.length !== 0 && (
          <div className="absolute -top-1 -right-1 bg-primary w-[20px] h-[20px] text-center flex flex-col items-center justify-center rounded-full">
            <span className="text-white text-[14px] mt-0.5">
              {wishlistData?.length > 9 ? "9+" : wishlistData?.length}
            </span>
          </div>
        )}
      </Link>
    );
  };

  const navigate = useNavigate();
  const searchProductsHandle = (value) => {
    setSearchProductsVal(value);
  };

  const searchNavigationHandle = (e) => {
    if (e.key === "Enter") {
      if (
        e.key === "Enter" &&
        !location.pathname.includes("search") &&
        searchProductsVal === ""
      ) {
        return;
      }
      if (location.pathname.includes("search") && searchProductsVal === "") {
        navigate(`/search`);
      } else {
        navigate(`/search/${searchProductsVal}`);
      }
    }
  };

  const mobileSearchNavigationHandle = (e) => {
    if (e.key === "Enter") {
      if (
        e.key === "Enter" &&
        !location.pathname.includes("search") &&
        searchProductsVal === ""
      ) {
        return;
      }
      if (location.pathname.includes("search") && searchProductsVal === "") {
        navigate(`/search`);
        setSearchToggle(false);
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
      } else {
        navigate(`/search/${searchProductsVal}`);
        setSearchToggle(false);
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
      }
    }
  };

  const [showBrowse, setShowBrowse] = useState(false);
  const [categoryData, setCategoryData] = useState([]);

  const sortCategories = (data) => {
    if (data) {
      return data
        .map((category) => ({
          ...category,
          sortOrder: parseInt(category.sortOrder, 10), // Convert sortOrder to a number
          children: sortCategories(category.children), // Recursively sort children
        }))
        .sort((a, b) => a.sortOrder - b.sortOrder); // Sort current level by sortOrder
    }
  };

  useEffect(() => {
    if (headerCategoriesData) {
      const sorted = sortCategories(headerCategoriesData);
      setCategoryData(sorted);
    }
  }, [headerCategoriesData]);

  const [oneSubCategoryData, setOneSubCategoryData] = useState([]);
  const [twoSubCategoryData, setTwoSubCategoryData] = useState([]);
  const [threeSubCategoryData, setThreeSubCategoryData] = useState([]);
  const [fourSubCategoryData, setFourSubCategoryData] = useState([]);
  const [fiveSubCategoryData, setFiveSubCategoryData] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedOneSubCategory, setSelectedOneSubCategory] = useState([]);
  const [selectedTwoSubCategory, setSelectedTwoSubCategory] = useState([]);
  const [selectedThreeSubCategory, setSelectedThreeSubCategory] = useState([]);
  const [selectedFourSubCategory, setSelectedFourSubCategory] = useState([]);
  const [selectedFiveSubCategory, setSelectedFiveSubCategory] = useState([]);

  return (
    <>
      <Breakpoint large up>
        <div className="header p-f t-0 l-0 w-100 ">
          {/* <div className="top-links">
            <Link to="/aboutus">About Us</Link>
            <Link to="/search">Browse Collections</Link>
            <Link to="/lens">Buy Lenses</Link>
            <Link to="/">Home Tryon</Link>
            <Link to="/search">Offers %</Link>
            <Link to="/blog">Blogs</Link>
            <Link to="/become-retailer">For Business</Link>
          </div> */}
          <div
            className="header-top bg-1"
            onMouseOver={() => {
              setIsVisible(true);
            }}
          >
            <div className="d-grid grid-3 header-top-child emd:py-[10px] items-center">
              <div className="d-flex a-c">
                <INPUT
                  isNotValidate
                  placeHolder={"Search"}
                  className={"py-0 px-0 w-full  border-0"}
                  mainClass={"p-0 m-0 w-[200px] "}
                  val={searchProductsVal}
                  onChange={(s) => searchProductsHandle(s.target?.value)}
                  onKeyDown={(e) => searchNavigationHandle(e)}
                  lComp={
                    <div className=" text-2xl me-1">
                      <img
                        src={SEARCH_IC}
                        alt=""
                        className="lg:w-8 emd:w-8 small:w-7"
                      />
                    </div>
                  }
                />
              </div>
              <div className="d-flex j-c a-c">
                <Link to="/">
                  <img
                    className="netre-logo"
                    src="/Netre-logo.svg"
                    alt="Netre"
                  />
                </Link>
              </div>
              <div className="d-flex j-e a-c header-right-icons">
                <FavouriteIcon />
                <div className="vertical-rule mx-2"></div>
                {/* <AccountIcon /> */}
                <div className={`relative flex items-center justify-center`}>
                  <Link
                    to={`${
                      user?.customer
                        ? "/profile-details#your-account"
                        : "/login"
                    }`}
                    className=" relative"
                  >
                    <img
                      src={PERSON_IC}
                      alt=""
                      className="lg:min-w-8 lg:max-w-8 emd:min-w-8 emd:max-w-8 small:min-w-7 small:max-w-7 m-auto"
                    />
                  </Link>
                </div>
                <div className="vertical-rule mx-2"></div>
                <button
                  // disabled={myBagData?.length === 0}
                  className="relative"
                  onClick={() => {
                    dispatch(myBagToggle());
                    document.body.style.overflow = "hidden";
                    document.body.style.height = "100vh";
                  }}
                >
                  <img
                    src={BAG_IC}
                    alt=""
                    className="lg:w-8 emd:w-8 small:w-7"
                  />
                  {myBagData?.length !== 0 && (
                    <div className="absolute -top-1 -right-1 bg-primary w-[20px] h-[20px] text-center flex flex-col items-center justify-center rounded-full">
                      <span className="text-white text-[14px] mt-0.5">
                        {myBagData?.length > 9 ? "9+" : myBagData?.length}
                      </span>
                    </div>
                  )}
                  {homeTryOnData?.length !== 0 && myBagData?.length === 0 && (
                    <div className="absolute -top-1 -right-1 bg-primary w-[20px] h-[20px] text-center flex flex-col items-center justify-center rounded-full">
                      <span className="text-white text-[14px] mt-0.5">
                        {homeTryOnData?.length > 9
                          ? "9+"
                          : homeTryOnData?.length}
                      </span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div
            className="header-bottom relative justify-center flex bg-1"
            style={{
              display: isVisible ? "flex" : "none",
              transition: "0.5s all ease",
            }}
          >
            <div
              onMouseLeave={() => setShowBrowse(false)}
              className="w-fit flex justify-between items-center"
            >
              {/* <div
                onMouseEnter={() => {
                  setShowBrowse(false);
                  setOneSubCategoryData([]);
                }}
                onClick={() => {
                  navigate(`/aboutus`);
                }}
                className="items mont-regular text-nowrap whitespace-pre "
              >
                About Us
              </div> */}
              <div
                onMouseEnter={() => {
                  setShowBrowse(true);
                  setOneSubCategoryData(
                    categoryData?.length > 0 ? categoryData[0]?.children : []
                  );
                }}
                className="items mont-regular text-nowrap whitespace-pre  ms-10 relative"
              >
                <span className="" onClick={() => navigate("/")}>
                  Browse Collection
                </span>
                {showBrowse && (
                  <div
                    onMouseLeave={() => {
                      setShowBrowse(false);
                      setOneSubCategoryData([]);
                      setTwoSubCategoryData([]);
                      setThreeSubCategoryData([]);
                      setFourSubCategoryData([]);
                      setFiveSubCategoryData([]);
                      setSelectedCategory([]);
                      setSelectedOneSubCategory([]);
                      setSelectedTwoSubCategory([]);
                      setSelectedThreeSubCategory([]);
                      setSelectedFourSubCategory([]);
                      setSelectedFiveSubCategory([]);
                    }}
                    className="absolute shadow-c_shadow bg-gray items-start justify-start z-10 flex left-0 top-[4.2rem]"
                  >
                    <div className="relative w-fit flex flex-col py-2 bg-white min-w-52">
                      {categoryData?.map((cat, index) => {
                        return (
                          <div
                            key={index}
                            className={`relative py-2 text-start me-4 w-full text-ellipsis overflow-hidden`}
                            onMouseEnter={() => {
                              setOneSubCategoryData(cat?.children);
                              setSelectedCategory(cat);
                            }}
                            onClick={() => {
                              navigate(
                                cat?.slug
                                  ? `/categories/${cat?.slug}`
                                  : `/categories/${cat?._id}`
                              );
                              setShowBrowse(false);
                              setOneSubCategoryData([]);
                              setTwoSubCategoryData([]);
                              setThreeSubCategoryData([]);
                              setFourSubCategoryData([]);
                              setFiveSubCategoryData([]);
                              setSelectedCategory([]);
                              setSelectedOneSubCategory([]);
                              setSelectedTwoSubCategory([]);
                              setSelectedThreeSubCategory([]);
                              setSelectedFourSubCategory([]);
                              setSelectedFiveSubCategory([]);
                            }}
                          >
                            <div
                              className={` flex items-center relative ${
                                selectedCategory?._id === cat?._id
                                  ? "title-container text-primary transition-all duration-300 bg-gray"
                                  : ""
                              } ps-4 py-2`}
                            >
                              {cat?.name?.en}
                              {cat?.children?.length > 0 && (
                                <span
                                  className={`${
                                    selectedCategory?._id === cat?._id
                                      ? "text-primary"
                                      : "text-secondary"
                                  } ms-2  text-xs`}
                                >
                                  ⟫
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {oneSubCategoryData?.length > 0 && (
                      <div className="w-fit flex flex-col py-2 bg-gray min-w-52">
                        {oneSubCategoryData?.map((oneSubCat, index) => {
                          return (
                            <div
                              key={index}
                              className={`relative py-2 text-start me-4 space-y-2 text-ellipsis overflow-hidden`}
                              onMouseEnter={() => {
                                setTwoSubCategoryData(oneSubCat?.children);
                                setSelectedOneSubCategory(oneSubCat);
                              }}
                              onClick={() => {
                                navigate(
                                  oneSubCat?.slug
                                    ? `/categories/${oneSubCat?.slug}`
                                    : `/categories/${oneSubCat?._id}`
                                );
                                setShowBrowse(false);
                                setOneSubCategoryData([]);
                                setTwoSubCategoryData([]);
                                setThreeSubCategoryData([]);
                                setFourSubCategoryData([]);
                                setFiveSubCategoryData([]);
                                setSelectedCategory([]);
                                setSelectedOneSubCategory([]);
                                setSelectedTwoSubCategory([]);
                                setSelectedThreeSubCategory([]);
                                setSelectedFourSubCategory([]);
                                setSelectedFiveSubCategory([]);
                              }}
                            >
                              <div
                                className={` flex items-center relative ${
                                  selectedOneSubCategory?._id === oneSubCat?._id
                                    ? "title-container text-primary transition-all duration-300"
                                    : ""
                                } ps-4 py-2`}
                              >
                                {oneSubCat?.name?.en}{" "}
                                {oneSubCat?.children?.length > 0 && (
                                  <span
                                    className={`${
                                      selectedOneSubCategory?._id ===
                                      oneSubCat?._id
                                        ? "text-primary"
                                        : "text-secondary"
                                    } ms-2  text-xs`}
                                  >
                                    ⟫
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {twoSubCategoryData?.length > 0 && (
                      <div className="w-fit flex flex-col py-2 min-w-52 overflow-hidden">
                        {twoSubCategoryData?.map((twoSubCat, index) => {
                          return (
                            <div
                              key={index}
                              className={`relative py-2 text-start me-4 space-y-2 text-ellipsis overflow-hidden`}
                              onMouseEnter={() => {
                                setThreeSubCategoryData(twoSubCat?.children);
                                setSelectedTwoSubCategory(twoSubCat);
                              }}
                              onClick={() => {
                                navigate(
                                  twoSubCat?.slug
                                    ? `/categories/${twoSubCat?.slug}`
                                    : `/categories/${twoSubCat?._id}`
                                );
                                setShowBrowse(false);
                                setOneSubCategoryData([]);
                                setTwoSubCategoryData([]);
                                setThreeSubCategoryData([]);
                                setFourSubCategoryData([]);
                                setFiveSubCategoryData([]);
                                setSelectedCategory([]);
                                setSelectedOneSubCategory([]);
                                setSelectedTwoSubCategory([]);
                                setSelectedThreeSubCategory([]);
                                setSelectedFourSubCategory([]);
                                setSelectedFiveSubCategory([]);
                              }}
                            >
                              <div
                                className={` flex items-center relative ${
                                  selectedTwoSubCategory?._id === twoSubCat?._id
                                    ? "title-container text-primary transition-all duration-300"
                                    : ""
                                } ps-4 py-2`}
                              >
                                {twoSubCat?.name?.en}
                                {twoSubCat?.children?.length > 0 && (
                                  <span
                                    className={`${
                                      selectedTwoSubCategory?._id ===
                                      twoSubCat?._id
                                        ? "text-primary"
                                        : "text-secondary"
                                    } ms-2  text-xs`}
                                  >
                                    ⟫
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {threeSubCategoryData?.length > 0 && (
                      <div className="w-fit flex flex-col py-2 min-w-52 overflow-hidden">
                        {threeSubCategoryData?.map((threeSubCat, index) => {
                          return (
                            <div
                              key={index}
                              className={`relative py-2 text-start me-4 space-y-2 text-ellipsis overflow-hidden`}
                              onMouseEnter={() => {
                                setFourSubCategoryData(threeSubCat?.children);
                                setSelectedThreeSubCategory(threeSubCat);
                              }}
                              onClick={() => {
                                navigate(
                                  threeSubCat?.slug
                                    ? `/categories/${threeSubCat?.slug}`
                                    : `/categories/${threeSubCat?._id}`
                                );
                                setShowBrowse(false);
                                setOneSubCategoryData([]);
                                setTwoSubCategoryData([]);
                                setThreeSubCategoryData([]);
                                setFourSubCategoryData([]);
                                setFiveSubCategoryData([]);
                                setSelectedCategory([]);
                                setSelectedOneSubCategory([]);
                                setSelectedTwoSubCategory([]);
                                setSelectedThreeSubCategory([]);
                                setSelectedFourSubCategory([]);
                                setSelectedFiveSubCategory([]);
                              }}
                            >
                              <div
                                className={` flex items-center relative ${
                                  selectedThreeSubCategory?._id ===
                                  threeSubCat?._id
                                    ? "title-container text-primary transition-all duration-300"
                                    : ""
                                } ps-4 py-2`}
                              >
                                {threeSubCat?.name?.en}
                                {threeSubCat?.children?.length > 0 && (
                                  <span
                                    className={`${
                                      selectedThreeSubCategory?._id ===
                                      threeSubCat?._id
                                        ? "text-primary"
                                        : "text-secondary"
                                    } ms-2  text-xs`}
                                  >
                                    ⟫
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {fourSubCategoryData?.length > 0 && (
                      <div className="w-fit flex flex-col py-2 min-w-52 overflow-hidden">
                        {fourSubCategoryData?.map((fourSubCat, index) => {
                          return (
                            <div
                              key={index}
                              className={`relative py-2 text-start me-4 space-y-2 text-ellipsis overflow-hidden`}
                              onMouseEnter={() => {
                                setFiveSubCategoryData(fourSubCat?.children);
                                setSelectedFourSubCategory(fourSubCat);
                              }}
                              onClick={() => {
                                navigate(
                                  fourSubCat?.slug
                                    ? `/categories/${fourSubCat?.slug}`
                                    : `/categories/${fourSubCat?._id}`
                                );
                                setShowBrowse(false);
                                setOneSubCategoryData([]);
                                setTwoSubCategoryData([]);
                                setThreeSubCategoryData([]);
                                setFourSubCategoryData([]);
                                setFiveSubCategoryData([]);
                                setSelectedCategory([]);
                                setSelectedOneSubCategory([]);
                                setSelectedTwoSubCategory([]);
                                setSelectedThreeSubCategory([]);
                                setSelectedFourSubCategory([]);
                                setSelectedFiveSubCategory([]);
                              }}
                            >
                              <div
                                className={` flex items-center relative ${
                                  selectedFourSubCategory?._id ===
                                  fourSubCat?._id
                                    ? "title-container text-primary transition-all duration-300"
                                    : ""
                                } ps-4 py-2`}
                              >
                                {fourSubCat?.name?.en}
                                {fourSubCat?.children?.length > 0 && (
                                  <span
                                    className={`${
                                      selectedFourSubCategory?._id ===
                                      fourSubCat?._id
                                        ? "text-primary"
                                        : "text-secondary"
                                    } ms-2  text-xs`}
                                  >
                                    ⟫
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {fiveSubCategoryData?.length > 0 && (
                      <div className="w-fit flex flex-col py-2 min-w-52 overflow-hidden">
                        {fiveSubCategoryData?.map((fiveSubCat, index) => {
                          return (
                            <div
                              key={index}
                              className={`relative py-2 text-start me-4 space-y-2 text-ellipsis overflow-hidden`}
                              onMouseEnter={() => {
                                setSelectedFiveSubCategory(fiveSubCat);
                              }}
                              onClick={() => {
                                navigate(
                                  fiveSubCat?.slug
                                    ? `/categories/${fiveSubCat?.slug}`
                                    : `/categories/${fiveSubCat?._id}`
                                );
                                setShowBrowse(false);
                                setOneSubCategoryData([]);
                                setTwoSubCategoryData([]);
                                setThreeSubCategoryData([]);
                                setFourSubCategoryData([]);
                                setFiveSubCategoryData([]);
                                setSelectedCategory([]);
                                setSelectedOneSubCategory([]);
                                setSelectedTwoSubCategory([]);
                                setSelectedThreeSubCategory([]);
                                setSelectedFourSubCategory([]);
                                setSelectedFiveSubCategory([]);
                              }}
                            >
                              <div
                                className={`flex items-center relative ${
                                  selectedFiveSubCategory?._id ===
                                  fiveSubCat?._id
                                    ? "title-container text-primary transition-all duration-300"
                                    : ""
                                } ps-4 py-2`}
                              >
                                {fiveSubCat?.name?.en}
                                {fiveSubCat?.children?.length > 0 && (
                                  <span
                                    className={`${
                                      selectedFiveSubCategory?._id ===
                                      fiveSubCat?._id
                                        ? "text-primary"
                                        : "text-secondary"
                                    } ms-2  text-xs`}
                                  >
                                    ⟫
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div
                onMouseEnter={() => {
                  setShowBrowse(false);
                  setOneSubCategoryData([]);
                }}
                onClick={() => {
                  navigate(`/search/price=best-selling`);
                }}
                className="items mont-regular text-nowrap whitespace-pre  ms-10"
              >
                Best Sellers
              </div>
              <div
                onMouseEnter={() => {
                  setShowBrowse(false);
                  setOneSubCategoryData([]);
                }}
                onClick={() => {
                  navigate(`/home-tryon`);
                }}
                className="items mont-regular text-nowrap whitespace-pre  ms-10"
              >
                <img
                  src={HTO_LOGO}
                  alt=""
                  className="min-w-[60px] max-w-[60px]"
                />
              </div>
              <div
                onMouseEnter={() => {
                  setShowBrowse(false);
                  setOneSubCategoryData([]);
                }}
                onClick={() => {
                  navigate(`/lens-only`);
                }}
                className="items mont-regular text-nowrap whitespace-pre  ms-10"
              >
                Lens Only
              </div>
              <div
                onMouseEnter={() => {
                  setShowBrowse(false);
                  setOneSubCategoryData([]);
                }}
                onClick={() => {
                  navigate(`/search/price=in-offer`);
                }}
                className="items mont-regular text-nowrap whitespace-pre  ms-10"
              >
                Offers
              </div>
              <div
                onMouseEnter={() => {
                  setShowBrowse(false);
                  setOneSubCategoryData([]);
                }}
                onClick={() => {
                  navigate(`/become-retailer`);
                }}
                className="items mont-regular text-nowrap whitespace-pre  ms-10"
              >
                For Business
              </div>
            </div>
          </div>
        </div>
      </Breakpoint>

      {/* =========================== FOR MOBILE AND TABLET =============================  */}

      <Breakpoint
        className={`${searchToggle ? "z-9999999 bg-green" : ""}`}
        medium
        down
      >
        <div className={`header p-f t-0 l-0 w-100`}>
          <div
            className="header-top bg-1"
            onMouseOver={() => {
              setIsVisible(true);
            }}
          >
            <div className="d-grid grid-3 header-top-child">
              <div className="d-flex a-c">
                <div className="" onClick={() => setSidebarToggle(true)}>
                  <img
                    src={MENU_IC}
                    alt=""
                    className="lg:w-8 emd:w-8 small:w-7"
                  />
                </div>
                <div className="vertical-rule mx-2"></div>
                <div
                  className=""
                  onClick={() => {
                    setSearchToggle(true);
                    setSearchProductsVal("");
                  }}
                >
                  <img
                    src={!searchToggle ? SEARCH_IC : CLOSE_IC}
                    alt=""
                    className="lg:w-8 emd:w-8 small:w-7"
                  />
                </div>
              </div>
              <div className="d-flex j-c a-c">
                <Link to="/">
                  <img
                    className="netre-logo lg:w-auto emd:w-auto small:w-20"
                    src="/Netre-logo.svg"
                    alt="Netre"
                  />
                </Link>
              </div>
              <div className="flex items-center justify-end header-right-icons">
                {/* <AccountIcon /> */}
                <div className={`relative flex items-center justify-center`}>
                  <Link
                    to={`${
                      user?.customer
                        ? "/profile-details#your-account"
                        : "/login"
                    }`}
                    className=" relative"
                  >
                    <img
                      src={PERSON_IC}
                      alt=""
                      className="lg:min-w-8 lg:max-w-8 emd:min-w-8 emd:max-w-8 small:min-w-7 small:max-w-7 m-auto"
                    />
                  </Link>
                </div>
                <div className="vertical-rule mx-2"></div>
                <button
                  className=" relative me-2"
                  onClick={() => {
                    dispatch(myBagToggle());
                    document.body.style.overflow = "hidden";
                    document.body.style.height = "100vh";
                  }}
                >
                  <img
                    src={BAG_IC}
                    alt=""
                    className="lg:w-8 emd:w-8 small:w-7"
                  />
                  {myBagData?.length !== 0 && (
                    <div className="absolute -top-1 -right-1 bg-primary w-[20px] h-[20px] text-center flex flex-col items-center justify-center rounded-full">
                      <span className="text-white text-[14px] mt-0.5">
                        {myBagData?.length > 9 ? "9+" : myBagData?.length}
                      </span>
                    </div>
                  )}
                  {homeTryOnData?.length !== 0 && myBagData?.length === 0 && (
                    <div className="absolute -top-1 -right-1 bg-primary w-[20px] h-[20px] text-center flex flex-col items-center justify-center rounded-full">
                      <span className="text-white text-[14px] mt-0.5">
                        {homeTryOnData?.length > 9
                          ? "9+"
                          : homeTryOnData?.length}
                      </span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
          {searchToggle ? (
            <div className="absolute bg-white p-5 top-14 w-full z-9999 flex items-center justify-center">
              <INPUT
                placeHolder="Search..."
                val={searchProductsVal}
                onChange={(s) => setSearchProductsVal(s?.target?.value)}
                onKeyDown={(e) => mobileSearchNavigationHandle(e)}
              />
            </div>
          ) : (
            <></>
          )}
          {searchToggle && (
            <div
              className={`fixed top-0 left-0 w-full h-full transition-all duration-75 z-999 ${
                searchToggle ? "opacity-50 " : ""
              }`}
              style={{
                background:
                  "linear-gradient(to bottom, transparent, #0000004c)",
              }}
              onClick={() => {
                setSearchToggle(false);
                document.body.style.overflow = "auto";
                document.body.style.height = "auto";
              }}
            ></div>
          )}
        </div>
      </Breakpoint>
    </>
  );
}
export default Header;

{
  /* {headerLabelsData?.labels?.homepage_category_to_show &&
              [...headerLabelsData?.labels?.homepage_category_to_show]
                .reverse()
                ?.splice(1)
                ?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        navigate(`/categories/${item?.id}`);
                      }}
                      key={index}
                      className="items mont-regular text-nowrap whitespace-pre "
                    >
                      {item?.name}
                    </div>
                  );
                })} */
}

{
  /* {showBrowse && (
                <div className="absolute 2xl:w-[45%] exl:w-[55%] xl:w-[45%] lg:w-[75%] emd:w-full cursor-pointer bg-white flex left-[50%] top-[4.2rem] -translate-x-1/2 shadow-md">
                  <div className="flex flex-col">
                    {groupedData?.map((item, index) => {
                      return (
                        <span
                          key={index}
                          // onClick={() => {
                          //   navigate(`/categories/${item?.id}`);
                          //   setShowBrowse(false);
                          // }}
                          className={` ${
                            showSubCategory[0]?.parentId === item?._id
                              ? "bg-gray text-primary"
                              : ""
                          } hover:bg-gray hover:text-primary py-3 px-4 relative flex items-start w-full cursor-pointer text-nowrap whitespace-pre  mb-2`}
                          // onClick={() => setShowSubCategory(item)}
                          onMouseEnter={() =>
                            setShowSubCategory(item?.subCategories)
                          }
                        >
                          {item?.name?.en}
                        </span>
                      );
                    })}
                  </div>
                  {showSubCategory?.length > 0 ? (
                    <div className="cursor-pointer 2xl:w-full exl:w-full xl:w-full lg:w-full emd:w-full bg-gray grid grid-cols-3 auto-rows-auto lg:gap-x-4 xl:gap-x-[16px] lg:gap-y-[16px] xl:gap-y-[16px] min-h-72 items-start overflow-hidden px-8 py-4 shadow-md">
                      {showSubCategory?.map((subItem, index) => {
                        return (
                          <div
                            key={index}
                            className="xl:w-[160px] lg:w-[160px] flex text-center flex-col items-center justify-center mx-auto"
                            onClick={() => {
                              navigate(`/categories/${subItem?._id}`);
                              setShowBrowse(false);
                            }}
                          >
                            <div className="w-full bg-white flex items-center justify-center">
                              <img
                                src={subItem?.icon}
                                alt=""
                                className="object-contain"
                              />
                            </div>
                            <span className="text-xs mt-3 px-1">
                              {subItem?.name?.en}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="cursor-pointer xl:w-full lg:w-full emd:w-full bg-gray grid grid-cols-3 auto-rows-auto lg:gap-x-4 xl:gap-x-4 lg:gap-y-4 xl:gap-y-4 min-h-72 items-start overflow-hidden px-8 py-4 shadow-md"></div>
                  )}
                </div>
              )} */
}

// const [groupedData, setGroupedData] = useState([]);

// useEffect(() => {
//   if (!headerCategoriesData || !Array.isArray(headerCategoriesData)) return;
//   const mainCategories = headerCategoriesData.filter(
//     (item) => item.parentName === "Home"
//   );

//   // Step 2: Sort the main categories by sortOrder
//   mainCategories.sort((a, b) => a.sortOrder - b.sortOrder);

//   // Step 3: Add subcategories to each main category
//   const categorizedData = mainCategories.map((mainCategory) => {
//     // Step 4: Filter and sort subcategories for the current main category
//     const subCategories = headerCategoriesData
//       .filter((sub) => sub.parentId === mainCategory._id)
//       .sort((a, b) => a.sortOrder - b.sortOrder);

//     // Add subcategories to the main category object
//     return {
//       ...mainCategory,
//       subCategories, // Add sorted subcategories
//     };
//   });
//   setGroupedData(categorizedData);
// }, [headerCategoriesData]);

// const renderMenuItems = (categoryList) => {
//   if (categoryList) {
//     return categoryList.map((category) => (
//       <li key={category._id} className="relative group">
//         <div className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 ps-4 me-6 py-2">
//           <span>{category.name.en}</span>
//           {category.children.length > 0 && (
//             <span className="ml-auto me-4">»</span>
//           )}
//         </div>
//         {category.children.length > 0 && (
//           <ul className="absolute left-full -top-full min-w-full hidden group-hover:block bg-white shadow-lg z-10">
//             {renderMenuItems(category.children)}
//           </ul>
//         )}
//       </li>
//     ));
//   }
// };

{
  /* {showBrowse && (
                <div
                  onMouseLeave={() => setShowInSubCategory([])}
                  className="absolute exl:w-[55%] xl:w-[60%] fix_lg:w-[100%] emd:w-full cursor-pointer bg-white flex left-[50%] top-[4.2rem] -translate-x-1/2 shadow-md"
                >
                  <div className="flex flex-col">
                    {headerCategoriesData?.map((cat, index) => {
                      return (
                        <span
                          key={index}
                          onClick={() => {
                            navigate(`/categories/${cat?._id}`);
                            setShowBrowse(false);
                          }}
                          className={` ${
                            showSubCategory[0]?.parentId === cat?._id
                              ? "bg-gray text-primary"
                              : ""
                          } hover:bg-gray hover:text-primary py-3 px-4 relative text-center flex items-start w-full cursor-pointer text-nowrap whitespace-pre  mb-2`}
                          // onClick={() => setShowSubCategory(item)}
                          onMouseEnter={() => {
                            setShowSubCategory(cat?.children);
                          }}
                        >
                          {cat?.name?.en}
                        </span>
                      );
                    })}
                  </div>
                  <div
                    onMouseLeave={() => setShowInSubCategory([])}
                    className={` ${
                      showSubCategory?.length > 0 ? "py-4" : ""
                    } cursor-pointer min-w-[18%] max-w-[22%] bg-gray min-h-72 items-start overflow-hidden shadow-md`}
                  >
                    {showSubCategory?.map((subItem, index) => {
                      return (
                        <div
                          key={index}
                          className="hover:bg-white text-wrap hover:text-primary py-3 px-[12px] relative flex items-start w-full cursor-pointer text-start flex-col justify-center mx-auto"
                          onClick={() => {
                            navigate(`/categories/${subItem?._id}`);
                            setShowBrowse(false);
                          }}
                          onMouseEnter={() =>
                            setShowInSubCategory(subItem?.children)
                          }
                          // onMouseLeave={() => setShowInSubCategory([])}
                        >
                          {subItem?.name?.en}
                        </div>
                      );
                    })}
                  </div>
                  {showInSubCategory?.length > 0 && (
                    <div
                      onMouseLeave={() => setShowInSubCategory([])}
                      className="cursor-pointer lg:w-fit bg-white grid xl:grid-cols-3 lg:grid-cols-4 emd:grid-cols-4 auto-rows-auto exl:gap-x-[20px] xl:gap-x-[16px] lg:gap-x-[16px] min-h-72 items-start justify-center overflow-hidden px-4 py-4"
                    >
                      {showInSubCategory?.map((subItem, index) => {
                        return (
                          <div
                            key={index}
                            className="xl:w-[120px] lg:w-[120px] h-fit flex text-center flex-col items-center justify-center mx-auto"
                            onClick={() => {
                              navigate(`/categories/${subItem?._id}`);
                              setShowBrowse(false);
                            }}
                          >
                            <div className="w-full bg-white flex items-center justify-center">
                              <img
                                src={subItem?.icon || "/Netre-logo.svg"}
                                // src={"/grid_bg_4_desktop.png"}
                                alt=""
                                className="object-contain"
                              />
                            </div>
                            <span className="text-xs mt-3 px-1">
                              {subItem?.name?.en}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )} */
}
